/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import 'fonts.css';

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.font-cooper-hewitt {
  font-family: 'CooperHewitt', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.pt-1\.25 {
  padding-top: 5px;
}

.-z-10 {
  z-index: -10;
}

.title-text {
   -webkit-text-stroke: 1px #111827;
   color: white;
   text-shadow:
       3px 3px 0 #111827,
     -1px -1px 0 #111827,  
      1px -1px 0 #111827,
      -1px 1px 0 #111827,
       1px 1px 0 #111827;
}

.group:hover .group-hover\:translate-0 {
  transform: translateX(0px) translateY(0px);;
}

_::-webkit-full-page-media, _:future, :root input[type="email"]::placeholder {
  @apply transform -translate-y-0.5;
}

.text-highlight {
  --rgb-yellow: 253, 230, 138;  /* amber-200 */
  /* --rgb-yellow: 254, 243, 199;  /* amber-100 */
  background: linear-gradient(90deg, rgba(var(--rgb-yellow), 0) 0, rgba(var(--rgb-yellow), 0.75) 5%, rgba(var(--rgb-yellow), 0.25) 95%, rgba(var(--rgb-yellow), 0) 100%);
  border-radius: 0.1rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline;
  margin-left: -0.2em;
  margin-right: -0.2em;
  padding-left: 0.2em;
  padding-right: 0.2em;
}
