@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Bold.woff') format('woff'),
       url('fonts/CooperHewitt-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-BoldItalic.woff') format('woff'),
       url('fonts/CooperHewitt-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Thin.woff') format('woff'),
       url('fonts/CooperHewitt-Thin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-ThinItalic.woff') format('woff'),
       url('fonts/CooperHewitt-ThinItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Book.woff') format('woff'),
       url('fonts/CooperHewitt-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-BookItalic.woff') format('woff'),
       url('fonts/CooperHewitt-BookItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Semibold.woff') format('woff'),
       url('fonts/CooperHewitt-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-SemiboldItalic.woff') format('woff'),
       url('fonts/CooperHewitt-SemiboldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Medium.woff') format('woff'),
       url('fonts/CooperHewitt-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-MediumItalic.woff') format('woff'),
       url('fonts/CooperHewitt-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Light.woff') format('woff'),
       url('fonts/CooperHewitt-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-LightItalic.woff') format('woff'),
       url('fonts/CooperHewitt-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-Heavy.woff') format('woff'),
       url('fonts/CooperHewitt-Heavy.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'CooperHewitt';
  src: url('fonts/CooperHewitt-HeavyItalic.woff') format('woff'),
       url('fonts/CooperHewitt-HeavyItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}
